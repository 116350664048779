form.register-form .col-md-12 {
    margin-bottom: 20px;
}

.register-section .register-section-left {
    padding: 90px 39px;
}

.register-section .register-section-left h1{
    margin-bottom: 25px;
}

.register-section .register-section-left .family-image{
    margin-top: 30px;
    text-align: center;
}

.register-section .register-section-left .family-image img {
    width: 85%;
}

form.register-form {
    padding-top: 25px;
}

#register_otp_form input {
    width: 70px;
    display: initial;
    margin-right: 20px;
    text-align: center;
}

.otp-edit-btn{
    position: absolute;
    right: 25%;
}

#register_otp_form input {
    width: 70px !important;
    display: initial;
    margin-right: 20px !important;
    text-align: center !important;
    height: 50px !important;
}

.register-section .card{
    padding: 20px;
}