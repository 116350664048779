.logo{
    width: 140px;
}

nav{
    /*box-sizing: border-box;
    background: #FFFFFF;
    border-bottom: 1px solid #D9D9D9 !important;
    height: 100px; */
  
    padding: 0 !important;
    margin: 0 !important;
}

a.nav-item.nav-link.active {
    border-bottom: 4px solid #1159A1;
    color: #1159A1;
}

a.nav-item.nav-link{
    margin: 0 15px !important;
    font-weight: 500;
    /*
    font-size: 20px;
    line-height: 30px; */
}

nav a{
    padding: 20px; 
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height, or 150% */

    /* Black */

    color: #242729;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    z-index: 0;
}


nav .navbar-nav{
    position: absolute;
    right: 0;
}