
.tab-content > .active {
    display: block;
}

.nav-pills .nav-link, .nav-pills .show > .nav-link {
    padding: 20px 20px;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    border-right: 1px solid #ccc;
}


.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #41434C;
    background-color: #d4f2ff;
    padding: 20px 20px;
}

a.active > span.counter {
    background: var(--primary-color);
    color: white;
}

.tab-content {
    padding: 10px;
}

.guidelines-section{
    background: #fff0bc;
    border: 2px solid #BB9F0A;
    padding: 15px;
    text-align: center;
}

.form-section .form-group {
    padding: 10px 20px;
}
.form-section .form-group label {
    font-size: 12px;
}

 select.form-control{
    height: 48px;
}

.sign-and-confirm .lkjDVC {
    width: 100%;
    max-width: 100%;
    height: 250px;
}
.otp-section input[type="password"] {
    height: 50px !important;
    width: 50px !important;
    font-size: 20px !important;
}
