
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  } 

  .wrapper{
    max-width: 1080px;
    margin: 50px auto;
    padding: 0 20px;
    position: relative;
  }
  .wrapper .center-line{
    position: absolute;
    height: 80%;
    width: 4px;
    background:#E2E8F0;
    /*left: 50%;*/
    left:-5.5%;
    top: 20px;
    transform: translateX(-50%);
  }
  .wrapper .row{
    display: flex;
  }
  .wrapper .row-1{
    /*justify-content: flex-start;*/
    justify-content: center;
  }
  .wrapper .row-2{
    /*justify-content: flex-end; */ 
  }
  .wrapper .row section{
    background: #fff;
    border-radius: 5px;
    /*width: calc(50% - 40px); */
    padding: 20px;
    position: relative;
  }
  .wrapper .row section::before{
    position: absolute;
    content: "";
    height: 15px;
    width: 15px;
    background: #fff;
    top: 28px;
    z-index: -1;
    transform: rotate(45deg);
  }
  .row-1 section::before{
    right: -7px;
  }
  .row-2 section::before{
    left: -7px;
  }
  .row section .icon,
  .center-line .scroll-icon{
    position: absolute;
    background: #1159A1;
    height: 34px;
    width: 34px;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color:white;
    font-size: 17px;
    box-shadow: 0 0 0 4px #1159A1, inset 0 2px 0 rgba(0,0,0,0.08), 0 3px 0 4px rgba(0,0,0,0.05);
  }
  .center-line .scroll-icon{
    bottom: 0px;
    left: 50%;
    font-size: 25px;
    transform: translateX(-50%);
  }
  .row-1 section .icon{
    top: 15px;
    right: -60px;
  }
  .row-2 section .icon{
    top: 15px;
    left: -60px;
  }
  .row section .details,
  .row section .bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .row section .details .title {
    font-size: 18px;
    font-weight: 600;
  }
  
  .row section p{
    margin: 10px 0 17px 0;
  }
  .row section .bottom a{
    text-decoration: none;
    background: #1159A1;
    color: #fff;
    padding: 7px 15px;
    border-radius: 5px;
    /* font-size: 17px; */
    font-weight: 400;
    transition: all 0.3s ease;
  }
  .row section .bottom a:hover{
    transform: scale(0.97);
  }
  @media(max-width: 790px){
    .wrapper .center-line{
      left: 40px;
    }
    .wrapper .row{
      margin: 30px 0 3px 60px;
    }
    .wrapper .row section{
      width: 100%;
    }
    .row-1 section::before{
      left: -7px;
    }
    .row-1 section .icon{
      left: -60px;
    }
  }
  @media(max-width: 440px){
    .wrapper .center-line,
    .row section::before,
    .row section .icon{
      display: none;
    }
    .wrapper .row{
      margin: 10px 0;
    }
  }

  .wrapper .row section{
    padding-left: 0px;
  }