
.pakage-card {
    padding: 0;
}

.pakage-header {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    margin: 0px;
}

.button-lists.row {
    padding: 10px 30px;
}

.pakage-list {
    padding: 10px 30px;
}

.pakage-list .item {
    padding: 5px;
    font-size: 16px;
}

.pakage-footer {
    padding: 20px;
    border-top: 1px solid #ccc; 
    margin: 0px;
}

.button-lists .btn{
    background: white;
    border: 1px solid #1159A1;
    font-size: 12px;
    text-align: left;

}
.button-lists .btn:hover{
    background: white; 
    border: 1px solid #1159A1 !important; 
}

.button-lists .ul div{
    display: table-cell;
}