@media (max-width: 840px) {
    .mobile-full-width {
        width: 100%;
    }


    .navbar-collapse{
        z-index: 1;
    }

    nav .navbar-nav {
        position: inherit !important;  
        background: white;
    }

    .register-section .register-section-left {
        padding: 0 !important;
    }

    .register-section.container.row {
        width: auto;
    }
    

}