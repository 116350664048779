body {
    --primary-color: #1159a1;
    --info-color: #9ddbef;
    --warning-color: rgb(231, 178, 1);
    --danger-color: rgb(206, 55, 55);
    --bs-link-color: #1159a1; 
  }

  p, span, div, a, span, ul, li , button {
    font-family: 'Inter', sans-serif !important;
    line-height: 30px;
  }

 h1, h2, h3, h4, h5, h6 {
    font-family: 'Inter', sans-serif !important;
  }


.headerWpr{
    background-color: #fff;
}
div#root {
    background: #FAFAFA;
}
.card{ 
    border: 0px solid rgba(0, 0, 0, .125); 
    box-shadow: 0 25px 95px 5px rgb(0 0 0 / 5%);
}

  .someClass {
    color: var(--primary-color);
  }

.primary-color{
    color: var(--primary-color);
}
.info-color{
    color: var(--info-color);
}
.warning-color{
    color: var(--warning-color);
}
.danger-color{
    color: var(--danger-color);
}

.hdrCol, .headerWpr {
    align-items: center;
    display: flex;
    position: relative;
}

.headerWpr {
    border-bottom: 1px solid #edeff2;
    height: 48px;
    width: 100%;
}

.innercontainer {
    margin: 0 auto;
    max-width: 1140px;
    width: 100%;
}

.mainwrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}

.theme-color-blue{
    color: #0d6efd;
}

 
 .w-auto{
    width: auto;
    max-width: auto;
 }

.ml-5{
    margin-left: 5px;
}
.ml-10{
    margin-left: 10px;
}
.ml-15{
    margin-left: 15px;
}
.ml-20{
    margin-left: 20px;
}
.ml-25{
    margin-left: 25px;
}

.mr-5{
    margin-right: 5px;
}
.mr-10{
    margin-right: 10px;
}
.mr-15{
    margin-right: 15px;
}
.mr-20{
    margin-right: 20px;
}
.mr-25{
    margin-right: 25px;
}
.mt-5{
    margin-top: 5px;
}
.mt-10{
    margin-top: 10px;
}
.mt-15{
    margin-top: 15px;
}
.mt-20{
    margin-top: 20px;
}
.mt-25{
    margin-top: 25px;
}
.mt-30{
    margin-top: 30px;
}
.mt-35{
    margin-top: 35px;
}
.mt-40{
    margin-top: 40px;
}
.mt-45{
    margin-top: 45px;
}
.mt-50{
    margin-top: 50px;
}

.mb-5{
    margin-bottom: 5px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-15{
    margin-bottom: 15px;
}
.mb-20{
    margin-bottom: 20px;
}
.mb-25{
    margin-bottom: 25px;
}

.m-0{
    margin: 0px;
}
.m-5{
    margin: 5px;
}
.m-10{
    margin: 10px;
}
.m-15{
    margin: 15px;
}
.m-20{
    margin: 20px;
}
.m-25{
    margin: 25px;
}
.m-30{
    margin: 30px;
}

.p-0{
    padding: 0px;
}
.p-5{
    padding: 5px;
}
.p-10{
    padding: 10px;
}
.p-15{
    padding: 15px;
}
.p-20{
    padding: 20px;
}
.p-25{
    padding: 25px;
}
.p-30{
    padding: 30px;
}

.heading{ 
    font-weight: 700;
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 0.5px;
    color: #242729;
}

.p-65{
    padding: 65px;
}

p{
    text-align: left; 
    font-weight: 400;
    font-size: 14px;  
    color: #334155;
    transform: rotate(0.1deg);
}

div{
    text-align: left;
}

.schedule-a-call{
    
    flex-direction: row;
    align-items: flex-start;
    padding: 18px;
    gap: 8px;
    width: 225px;
    height: 60px;
    background: #1159A1 !important; 
    box-shadow: 4px 4px 16px rgba(17, 89, 161, 0.3);
    border-radius: 8px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white !important;
}

.explore-more{
   
font-weight: 600 !important;
font-size: 18px !important;
line-height: 24px;
/* identical to box height, or 133% */

text-align: center;

color: #1159A1 !important;

transform: rotate(0.1deg);

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}

.round{
    border-radius: 50%;
    color: #1159A1;
    padding: 2px;
    border: 2px solid #1159A1;
}

/**
footer section
*/
footer{ 
    background: #FBFBFB;
    border-top: 1px solid #D9D9D9;
    padding: 50px;
    margin-top: 25px;
}
footer .footer-logo {
    padding-right: 20%;
    padding-bottom: 20px;
}
footer .short-desc {
    padding-top: 25px;
}

footer p{ 
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D5055;

}
footer ul{ 
    padding-left:0px;
  }
footer ul li {
  list-style: none;
  padding-left:0px;
}
footer ul li a { 
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D5055;
    text-decoration:none;
}

footer strong{ 
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #242729;
}
 
footer hr{
    background: #E8E8E8;
    transform: matrix(1, 0, 0, -1, 0, 0);
}
footer .social-icons{
    text-align: right;
}
footer .social-icons a{
    margin: 0px 7px;    
}
.copyright-section p {
    padding-right: 50%;
}

/*

.btn-success{
    background: #50A042;
    border-radius: 8px;
    width: auto;
    padding: 18px;
    gap: 8pxpx; 
    height: 60px; 
    box-shadow: 4px 4px 16px rgb(17 89 161 / 30%);
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white !important;
}
.btn-success:hover{
    background: #50A042;
}

.btn-primary{
    background: #1159a1;

    border-radius: 8px;
    width: auto;
    padding: 18px;
    gap: 8pxpx; 
    height: 60px; 
    box-shadow: 2px 2px 10px #80abd6;
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: white !important;
}
.btn-primary:hover{
    background: #1159a1;
}

*/

.btn {
    width: auto;
}
.btn-primary{
    background: #1159a1;
    color: white;
}

.btn-primary:hover{
    background: #1159a1;
    color: white;
}
.btn-success{
    background: #50A042;
}

.btn-success:hover{
    background: #50A042;
}



.family-monochromatic {
    padding-top: 10%;
    text-align: center;
}


.family-monochromatic img{
    width: 80%;
}

.nav-tabs li.nav-item button {
    border: none; 
    color: #1E293B;
}

.nav-tabs li.nav-item button[aria-selected='true'] {
    border: none;
    border-bottom: 2px solid;
    color: #1159A1;
}

.full-width{
    width: 100%;
}

input[type="text"], 
input[type="date"], 
input[type="select"], 
input[type="email"], 
input[type="password"]{
    height: 48px;
    left: 0px;
    right: 0px;
    top: 20px;
    background: #FFFFFF;  
    border: 1px solid #CBD5E1;
    border-radius: 4px;

    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;

}

.fa_1x{
    width: 10px;
}
.fa_2x{
    width: 20px;
}
.fa_3x{
    width: 30px;
}
.fa_4x{
    width: 40px;
}
.fa_5x{
    width: 50px;
}
.fa_5x{
    width: 50px;
}
.fa_6x{
    width: 60px;
}

.fa_6x{
    width: 60px;
}

.fa_7x{
    width: 70px;
}

.fa_8x{
    width: 80px;
}

.fa_9x{
    width: 90px;
}
.fa_10x{
    width: 100px;
}


.fw300{font-weight:300}
.fw400{font-weight:400}
.fw500{font-weight:500}
.fw600{font-weight:600}
.fw700{font-weight:700}

.font8{font-size:8px}
.font9{font-size:9px}
.font10{font-size:10px}
.font11{font-size:11px}
.font13{font-size:13px}
.font12{font-size:12px}
.font14{font-size:14px}
.font15{font-size:15px}
.font16{font-size:16px}
.font17{font-size:17px}
.font18{font-size:18px}
.font19{font-size:19px}
.font20{font-size:20px}
.font21{font-size:21px}
.font22{font-size:22px}
.font23{font-size:23px}
.font24{font-size:24px}
.font25{font-size:25px}
.font26{font-size:26px}
.font27{font-size:27px}
.font28{font-size:28px}
.font29{font-size:29px}
.font30{font-size:30px}
.font36{font-size:36px}
.font37{font-size:37px}
.font38{font-size:38px}
.font39{font-size:39px} 
.font40{font-size:40px}
.font41{font-size:41px}
.font42{font-size:42px}
.font43{font-size:43px}
.font44{font-size:44px}
.font45{font-size:45px}
.font46{font-size:46px}
.font47{font-size:47px}
.font48{font-size:48px}

.lh30{line-height: 30px}
.lh50{line-height: 50px}
.lh46{line-height: 46px}
.lh56{line-height: 56px}

 
.w10{
    width: 10px;
}
.w15{
    width: 15px;
}
.w20{
    width: 20px;
}
.w25{
    width: 25px;
}
.w30{
    width: 30px;
}
.w35{
    width: 35px;
}
.w40{
    width: 40px;
}
.w50{
    width: 50px;
}
.w60{
    width: 60px;
}.w70{
    width: 70px;
}
.w80{
    width: 80px;
}

.required{
    color: red;
    padding-left: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: left calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);  
}

input.error{
    border-color: #dc3545;
    /*padding-right: calc(1.5em + 0.75rem);
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);*/

}

.hide{
    display: none;
}
.show{
    display: block;
}

.stepper-list {
    text-align: center;
    margin-bottom: 20px;
}
.stepper-list span {
    padding: 5px 10px;
    border-radius: 52%;
    color: #CBD5E1;
    font-weight: bold;
    margin: 0px 5px;
    border: 1px solid #CBD5E1;
}

.stepper-list span.active {
    background: var(--primary-color); 
    color: white; 
} 

.pointer {
    cursor: pointer;
}

.group-toggle button {
    background: white;
    color: #000000;
    width: 90px;
}
.group-toggle button.active {
    background-color: #E2E8F0;
    color: black;
}

.text-right{
    text-align: right;
}

.flot-left{
    float: left;
}

.btn-round {
    border-radius: 15px;
    border: 1px solid #BBCAF3;
}

.btn-default {
    border: 1px solid;
}
.btn:hover {
    border: 1px solid;
}

.right {
    text-align: right;
}
.left {
    text-align: left;
}
.center {
    text-align: center;
}
.btn-link{
    text-decoration: none;
}
.btn-lg {
    min-height: 56px;
    padding: 16px 32px;
    font-size: 16px;
    gap: 8px;
    border-radius: 4px;
}

.btn-sm, .btn-group-sm > .btn {
    --bs-btn-padding-y: 0.25rem;
    --bs-btn-padding-x: 0.5rem;
    --bs-btn-font-size: 1.0rem;
    --bs-btn-border-radius: 1.25rem;
}




h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
    font-weight: 600;
}

h1.title {
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
}

.sub-title {
    font-weight: 400;
    font-size: 20px;
}

.nowrap{
    white-space: nowrap;
}


ul.bullet-point-1 > li{
    background: url(https://manipalcigna.finnovate.in/assets/images/icons/correct-green.png) no-repeat left top;
    padding: 10px 0px 5px 30px;
    list-style: none;
    margin: 0;
    background-position-y: 15px;
}


span.counter {
    border: 1px solid #ccc;
    padding: 2px 10px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}


span.counter.fill {
    background-color: var(--primary-color);
    color: white;
}

.absolute-right{
    position: absolute;
    right: 0;
}


.btn-outline-primary {
    --bs-btn-color: var(--primary-color);
    --bs-btn-border-color: var(--primary-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--primary-color);
    --bs-btn-hover-border-color: var(--primary-color);
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--primary-color);
    --bs-btn-active-border-color: var(--primary-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--primary-color);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--primary-color);
    --bs-gradient: none;
}

select.form-control{
    appearance:auto;
}


.radio_button label>input[type="radio"] {
    display: none; 
}
.radio_button label { 
    cursor: pointer;
}
.radio_button label:has(input[type="radio"]) {
    border: 1px solid #ccc;
    padding: 10px 15px;
    font-size: 16px;
}

.radio_button label {
    border-radius: 0px 5px 5px 0px;
}

.radio_button label:first-child {
    border-radius: 5px 0px 0 5px;
}
 

.radio_button label:has(input[type="radio"]:checked) {
    background: rgb(189, 186, 186);
}

.disabled, .disabled input 
{
  pointer-events: none;
  opacity: 0.7;
}

button.btn.disabled {
    opacity: 0.4;
    color: black;
}


nav.navbar.navbar-expand-lg.navbar-light.bg-light {
    background-color: white !important;
}

.height-10 {height: 10px}
.height-20 {height: 20px}
.height-30 {height: 30px}
.height-40 {height: 40px}
.height-50 {height: 50px}
.height-100 {height: 150px}
.height-150 {height: 150px}
.height-200 {height: 200px}
.height-250 {height: 250px}
.height-300 {height: 300px}


.min-height-50 {min-height: 50px}
.min-height-100 {min-height: 150px}
.min-height-150 {min-height: 150px}
.min-height-200 {min-height: 200px}
.min-height-250 {min-height: 250px}
.min-height-300 {min-height: 300px}

.status_approved {
    color: green;
}

.status_pending {
    color: red;
}