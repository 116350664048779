.video-call-section{
    padding: 20px;
}

.video-call-section .card{
    background: #F1F7FC;
    padding: 30px 20px;
    ;
}
.video-img {
    text-align: center;
    padding-bottom: 25px;
}

.pakage-list img{
    float: left;
    margin-right: 10px;
}

.key-benefits .box > .card {
    padding: 15px;
    margin-top: 20px;
}

.key-benefits .box .card  {
    min-height: 185px;
}
.key-benefits .box .text {
    padding-top: 25px;
}

.key-benefits .box strong {
    position: absolute;
    left: 50px;
}

.pakage-summary-form .card {
    padding: 35px 20px;
}

.pakage-summary-form .card label {
    padding-top: 14px;
}

button.accordion-button {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}


span.approval_status_approved {
    color: green;
    font-size: 20px;
    font-weight: bold;
}

span.approval_status_pending {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

span.pending_label{
    background: #EBEEF3;
    border-radius: 32px;
    padding: 5px 16px; 
}
span.pending_label i.fa-arrow-up{ 
    rotate: 30deg;
}


span.approved_label{ 
    flex-direction: row;
    align-items: flex-start;
    padding: 5px 10px;
    gap: 10px; 
    width: 27px;
    height: 20px;
    left: 0px;
    top: 0px; 
    background: #D0F18A;
    border-radius: 32px;
}

.requested_quotes_details_card{
    background-repeat: no-repeat;
    background-position: right bottom;

}