td,th{
    padding: 10px !important;
}


td{
    font-weight: 500;
    font-size: 12px;  
    letter-spacing: -0.02em; 
    color: #475569;
} 
.thead-light{
    background-color: #efefef;
}

.header-table td{
    font-weight: 500;
    font-size: 14px;  
    letter-spacing: -0.02em; 
    color: #475569;
    vertical-align: top;
} 
