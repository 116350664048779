.back-arrow{
    margin-right: 20px;
}

.people-covered-option{
    padding: 10px;
    margin-top: 20px;
}
.people-covered-option .person {
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    width: 190px;
}

.people-covered-option .active { 
    border: 2px solid #1266B9; 
}


.people-covered-option .person input[type="checkbox"] {
    display: none;
}

.people-covered-option .person img {
    margin-right: 20px;
    margin-left: 10px;
}

.people-covered-option .option { 
    margin-bottom: 25px;
}

.input-area {
    margin: 5px 0px;
}

.dob-list input[type="date"] {
    float: right;
    width: 200px;
    padding-left: 10px;
}

.dob-list .input-area img {
    margin-right: 20px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    /*background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png) no-repeat; */
    background: url('../../assets/images/icons/calendar-icon.svg') no-repeat;
    
    width: 20px;
    height: 20px;
    border-width: thin;
}


.coverage-area-radio-buttons label {
    border: 1px solid #ccc;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
}

.coverage-area-radio-buttons label.active {
   border: 2px solid #1266B9;
    
}

.coverage-area-radio-buttons label input[type="radio"]{
    display: none; 
}


.people-covered-option input[type="number"] {
    width: 35px;
    height: 29px;
    background: #1266B9;
    color: white;
    font-weight: bold;
    text-align: center;
    border: 0px solid;
}

.people-covered-option label.children {
    margin-bottom: -20px;
}

.people-covered-option input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

.quantity {
    background: #1266B9;
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    margin-left: 70px;
}

span.quantity i {
    font-size: 23px;
    cursor: pointer;
}

.additional-information hr.bottom-border {
    margin-top: -10px;
    width: 70px;
    border: 2px solid #1266B9;
    opacity: 1;
    margin-left: 15px;
}

.additional-information .row {
    padding: 5px 0px;
}
.continue-btn-section {
    margin-top: 20px;
}

.dob-section .dob-list {
    padding: 10px 20px;
}
