.essay-step {
    background: #fff; 
    border-radius: 10px;
    padding: 10px 22px;
    border: 0px solid rgba(0, 0, 0, .125); 
    box-shadow: 0 25px 95px 5px rgb(0 0 0 / 5%);
}

.essay-step img {
    padding: 10px 3px;
}

.essay-step i.round_bg{    
    background: #1159A1;
    padding: 9px 11px;
    border-radius: 50%;
}


.essay-step p{ 
    font-weight: 500;
    font-size: 15px; 
    color: #1159A1;
}

.essay-step-section>div {
    padding: 0 5px;
}
.essay-step-section {
    margin-bottom: 20px;
}