.search-icon {
    width: 40px;
    margin-right: -10px;
    position: absolute;
    top: 24px;
    z-index: 9999;
    left: -39px;
    cursor: pointer;
}
.search-form input.search-box{

    padding-left: 50px;  
    border-radius: 20px !important;
    margin-left: -45px !important;
}