.modal-header {
    border-bottom: none;
}
.modal-body {
    margin-bottom: 25px;
    padding: 10px 40px;
}
.image-section{
    padding: 20px;
}


.section-2 {
    padding: 4% 0;
}

 

.section-1 img{
    margin-top: 20%;
    width: 95%;
}

