.insurance-application-status .card {
    padding: 20px; 
}
.insurance-application-status .card.fill {
    padding: 20px;
    background-color: #FAFBFF;
}

.insurance-application-status .card span.counter {
    border: 1px solid #ccc;
    padding: 2px 10px;
    border-radius: 50%;
    float: left;
    margin-right: 10px;
}
.insurance-application-status .card span.counter.fill {
    background-color: #0d6efd;
    color: white;
}

.insurance-application-status .card .text {
    padding-left: 5%;
    margin-top: 10px;
}

.pakage-summary-form .header img {
    width: 50px;
    float: left;
    margin-right: 20px;
}

.pakage-summary-form  label {
    font-size: 14px;
}

.insurance-application-status .warning-text{
    margin-top: -15px;
    margin-bottom: 5px;
}
.insurance-application-status .warning-text small {
    background: #ffff0063;
    color: #C59C0B;
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 0px;
    padding: 3px;
}

.enrollment_form_verified_status_rejected {
    color: red;
    font-weight: bold;
}

.enrollment_form_verified_status_approved {
    color: green;
    font-weight: bold;
}

