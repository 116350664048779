.persons-card img.profile-image {
    height: 190px;
    margin-bottom: -10px;
}

.persons-card {
    margin: 10px 2%;
    border: 1px solid #C2E1FF;
    padding: 10px; 
    padding-top: 35px;
    background-image: url('../../assets/images/icons/mdi_comma_right.png');
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-size: 75px;
    background-color: white;
}

.persons-card-section{
    background-image: url('../../assets/images/bg-images/persons-card-bg-1.png');
    background-repeat: no-repeat;
    background-position-x: 0;
    background-size: 20%;
}

.persons-card p {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px; 
    color: #242729;
}

.comma {
    width: 50px;
    height: 50px;
    margin-bottom: -57px;
    margin-left: 60px;
}
  
.carousel-indicators {
    position: absolute;
    right: -11%;
    bottom: -50px;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-bottom: 1rem;
    list-style: none;
    background: #E2E8F0;
    width: 200px;
    border-radius: 10px;
    left: auto;
}

.carousel-indicators [data-bs-target]{
    width: 40px;
    height: 9px;
}

.carousel-indicators [data-bs-target]{
    background-color: #1159A1;
}

.carousel-item .item{
    float: left;
}