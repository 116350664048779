.pricing-card {
    padding: 25px; 
    margin-top: 100px;
}
.active.pricing-card {
    margin-top: 40px;
    border: 3px solid #EDB43A;
    border-radius: 20px;
}


.pricing-card .name strong {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px; 
    letter-spacing: -0.04em;
    text-transform: uppercase; 
    color: #242729;
}

.pricing-card .interval span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: #242729;
}

.pricing-card .feature-list ul li {
    font-weight: 600;
    font-size: 16px;
    color: #4D5055;
    margin-bottom: 10px;
}
 
.feature-list ul {
    padding-left: 0px;
}

.pricing-card .feature-list ul li {
    background: url(https://manipalcigna.finnovate.in/assets/images/icons/correct_red.png) no-repeat left top;
    padding: 16px 0px 3px 35px;
    list-style: none;
    margin: 0;
    background-position-y: 15px;

  }