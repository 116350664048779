.modal-body {
    margin-bottom: 25px;
    padding: 10px 40px;
}

#submit_btn{
    width: 100%;
}

.privacy-policy {
    padding: 10px;
}

.privacy-policy p{
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* or 150% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #A6A6A6;

}