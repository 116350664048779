
body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family:  'Inter', sans-serif;
  font-style: normal  !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1500px){
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
      /*max-width: 1440px !important; */
  }
}

p, h1, h2, h3, h4, h5, h6, span, div, a, span, ul, li {
  font-family:  'Inter', sans-serif;
  font-style: normal  !important;
}

.theme-color{
  color: #1159A1;
}

.theme-bg{
  background-color: #1159A1;
}

