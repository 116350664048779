.home-bg{
    background-image: url('../assets/images/bg-images/sky.png') ;
    background-position-y: -100px;
}
 
.bg-2{
    background-image: url('../assets/images/bg-images/home-bg-2.png') !important;
}

.home-main-image-section{
    text-align: right;
}
.home-main-image-section img{
    width: 100%;
}

.helth-insurance .left-section {
    padding-top: 9%;
}

.scheme-offer .card {
    border-radius: 20px;
}

.mainwrapper .home{
    background-color: #fff;
}