.diseases-covered .icon{
    float: left;
    padding-right: 18px;
}

.diseases-covered .title{ 
    font-size: 20px;
}

.diseases-covered .details{
    margin-left: 80px;
}

.diseases-covered {
    padding: 10px;
}

.diseases-covered .col-md-6 {
    padding-bottom: 25px;
}

.w-200{
    width: 200px;
}